import {
  Header,
  Grid,
  Label,
  Divider,
  Segment,
  Button,
  Accordion,
  Icon,
  Message,
  Popup,
  Loader,
  Dropdown,
} from "semantic-ui-react";
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import Moment from "react-moment";
import { now } from "moment";
import { useNavigate } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import { useUserAccess } from "../components/UserAccessManager";

import UserDetails from "../components/UserDetails";
import VehicleDetails from "../components/VehicleDetails";
import EditBookingModal from "../components/modals/EditBookingModal";
import BookingOrders from "../components/BookingOrders";
import { useChatActions } from "../components/chat-window-manager/ChatWindowManager";

const Booking = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [render, setRender] = useState(Math.random());
  const [booking, setBooking] = useState();
  const [saveStatus, setSaveStatus] = useState({});
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [editBookingIndex, setEditBookingIndex] = useState(-1);
  const [isBookingEditorOpen, setIsBookingEditorOpen] = useState(false);

  const navigate = useNavigate();
  const { isCommunityManager } = useUserAccess();
  const { getAccessTokenSilently } = useAuth0();
  const { toggleOpenChatWindow, insertTemplate } = useChatActions();

  const options = { hourCycle: "h24", timeZoneName: "shortOffset" };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = await getAccessTokenSilently();

      const fetchedBooking = await api.getBookingById(id, accessToken);
      setBooking(fetchedBooking);
      setStartTime(new Date(fetchedBooking.startTime));
      setEndTime(new Date(fetchedBooking.endTime));

      setIsLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  const handleStartTimeChange = (newStartTime) => {
    setStartTime(newStartTime);
  };

  const handleEndTimeChange = (newEndTime) => {
    setEndTime(newEndTime);
  };

  const handleAccordionClick = (e, i) => {
    const { index } = i;
    setEditBookingIndex(editBookingIndex === index ? -1 : index);
  };

  const handleSaveClick = async () => {
    if (startTime > endTime) {
      setSaveStatus({
        status: "error",
        errorMessage: "StartTime must be BEFORE EndTime",
      });
      return;
    }

    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      startTime: startTime.toISOString(),
      endTime: endTime.toISOString(),
    };
    await api
      .patchBooking(booking.id, accessToken, requestObject)
      .then(() => {
        setSaveStatus({ status: "success", errorMessage: "" });
        setRender(Math.random());
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });
  };

  const handleOverrideSaveClick = async (editedBooking) => {
    if (startTime > endTime) {
      setSaveStatus({
        status: "error",
        errorMessage: "StartTime must be BEFORE EndTime",
      });
      return;
    }

    const accessToken = await getAccessTokenSilently();
    const requestObject = {
      startTime: editedBooking.startTime,
      endTime: editedBooking.endTime,
      actualStartTime: editedBooking.actualStartTime,
      actualEndTime: editedBooking.actualEndTime,
      status: editedBooking.status,
      rate: editedBooking.rate,
      mileageBefore: editedBooking.mileageBefore,
      mileageAfter: editedBooking.mileageAfter,
    };
    await api
      .patchBooking(booking.id, accessToken, requestObject)
      .then(() => {
        setSaveStatus({ status: "success", errorMessage: "" });
        setRender(Math.random());
      })
      .catch((_) => {
        setSaveStatus({
          status: "error",
          errorMessage: "API Error. Contact Ike",
        });
      });
  };

  const displayOverdueLabel = (booking) => {
    if (
      new Date() > new Date(booking.endTime) &&
      booking.status === "ongoing"
    ) {
      return (
        <>
          <Label color="red" content="overdue" icon="clock" /> by{" "}
          <Moment
            format="h[h] m[m]"
            trim
            duration={new Date(booking.endTime)}
            date={new Date(now())}
          />{" "}
        </>
      );
    } else return;
  };

  const displayEditWarningLabel = () => {
    if (booking.nextBookingTime === null) return <></>;
    if (new Date(endTime) >= new Date(booking.nextBookingTime)) {
      return (
        <Message negative>
          <Icon
            color="red"
            name="exclamation circle"
            size="large"
            floated="right"
          />{" "}
          Warning! May conflict with next booking
        </Message>
      );
    }
    return <></>;
  };

  const handleOpenBookingEditor = () => {
    setIsBookingEditorOpen(true);
  };

  const handleCloseBookingEditor = () => {
    setIsBookingEditorOpen(false);
  };

  if (isLoading) {
    return <Loader active size="big" />;
  }

  return (
    <>
      <Grid stackable columns="equal">
        <Grid.Column>
          <Popup
            position="top center"
            on="hover"
            content="Go to user profile"
            trigger={
              <Link to={`../users/${booking.user.id}`}>
                <Header as="h3" textAlign="center">
                  User Details
                </Header>
              </Link>
            }
          />

          <UserDetails user={booking.user} />

          <Grid textAlign="center" style={{ margin: 0 }}>
            <Grid.Column>
              <Button.Group
                size="mini"
                style={{
                  fontSize: ".78571429rem",
                  margin: "0px 0.5em 0.5em 0px",
                }}
              >
                <Button
                  size="mini"
                  as={Label}
                  onClick={() => toggleOpenChatWindow(booking.user)}
                >
                  <Icon
                    name="send"
                    style={{
                      margin: "0 .75em 0 0",
                      opacity: 1,
                      height: "auto",
                    }}
                  />
                  SMS
                </Button>

                <Dropdown as={Label} className="button icon" trigger={<></>}>
                  <Dropdown.Menu>
                    <Dropdown.Header content="Templates" />
                    <Dropdown.Divider />
                    {["Overdue booking", "Late return", "Delayed booking"].map(
                      (item) => (
                        <Dropdown.Item
                          onClick={() => insertTemplate(item, booking)}
                        >
                          {item}
                        </Dropdown.Item>
                      )
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
            </Grid.Column>
          </Grid>
        </Grid.Column>

        <Grid.Column>
          <Popup
            position="top center"
            on="hover"
            content="Go to vehicle profile"
            trigger={
              <Link to={`../vehicles/${booking.vehicle.id}`}>
                <Header as="h3" textAlign="center">
                  Vehicle Details
                </Header>
              </Link>
            }
          />

          <VehicleDetails
            vehicle={booking.vehicle}
            site={booking.vehicle.site}
            compact={true}
          />
          <Grid columns="equal" padded>
            <Grid.Column>
              <Header as="h4">Booking Site </Header>
              <Popup
                mouseEnterDelay={500}
                content="Go to site bookings"
                trigger={
                  <Button
                    basic
                    compact
                    icon="external alternate"
                    labelPosition="right"
                    content={booking.site.displayName}
                    onClick={() =>
                      navigate(`/sites/${booking.site.id}/vehiclebookings`)
                    }
                  />
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Header as="h4"> Booking Etag Number</Header>
              <Grid.Row>
                {" "}
                {booking.vehicleEtagNumber === null
                  ? "N/A"
                  : booking.vehicleEtagNumber}
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>

      <Divider />

      <Header as="h2" textAlign="center">
        Booking Details
      </Header>

      <Grid stackable columns="equal">
        <Grid.Column width={5}>
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h4">ID</Header>
                  {booking.id}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Header as="h4">Status</Header>
                  <Label className={booking.status}>{booking.status}</Label>
                  {displayOverdueLabel(booking)}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Header as="h4">Next Booking Time</Header>
                  {booking.nextBookingTime == null
                    ? "N/A"
                    : new Date(booking.nextBookingTime).toLocaleString(
                        "en-AU",
                        options
                      )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment>
            {saveStatus.status === "success" && (
              <Message positive>
                <Message.Header>Save was Successful!</Message.Header>
              </Message>
            )}
            {saveStatus.status === "error" && (
              <Message negative>
                <Message.Header>
                  Something went wrong - {saveStatus.errorMessage}
                </Message.Header>
              </Message>
            )}
            <Grid stackable columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <Header as="h4">Start Time</Header>
                  {new Date(booking.startTime).toLocaleString("en-AU", options)}
                </Grid.Column>

                <Grid.Column>
                  <Header as="h4">End Time</Header>
                  {new Date(booking.endTime).toLocaleString("en-AU", options)}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Accordion>
                  <Accordion.Title
                    active={editBookingIndex === 0}
                    index={0}
                    onClick={handleAccordionClick}
                  >
                    <Label color="teal" horizontal size="large">
                      Edit Booking &nbsp;&nbsp;&nbsp;
                      {editBookingIndex === -1 ? (
                        <Icon name="add" />
                      ) : (
                        <Icon name="minus" />
                      )}
                    </Label>
                  </Accordion.Title>
                  <Accordion.Content active={editBookingIndex === 0}>
                    <Grid stackable columns="equal">
                      <Grid.Row>
                        <Grid.Column>
                          <Header as="h4">New Start Time</Header>
                          <DateTimePicker
                            disableClock={true}
                            onChange={handleStartTimeChange}
                            value={startTime}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Header as="h4">New End Time</Header>
                          <DateTimePicker
                            disableClock={true}
                            onChange={handleEndTimeChange}
                            value={endTime}
                          />
                          {displayEditWarningLabel()}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row centered>
                        <Button onClick={handleSaveClick} color="teal">
                          Save
                        </Button>
                      </Grid.Row>
                      <Grid.Row columns={1} only="mobile">
                        {" "}
                        &nbsp;{" "}
                      </Grid.Row>
                      <Grid.Row centered>
                        {!isCommunityManager && (
                          <Button
                            icon
                            labelPosition="right"
                            color="red"
                            size="small"
                            onClick={handleOpenBookingEditor}
                          >
                            <Icon name="edit" />
                            Manual override
                          </Button>
                        )}
                        <EditBookingModal
                          isOpen={isBookingEditorOpen}
                          onClose={handleCloseBookingEditor}
                          booking={booking}
                          onSave={(editedBooking) =>
                            handleOverrideSaveClick(editedBooking)
                          }
                        />
                      </Grid.Row>
                      <Grid.Row columns={1} only="mobile">
                        {" "}
                        &nbsp;{" "}
                      </Grid.Row>
                    </Grid>
                  </Accordion.Content>
                </Accordion>
              </Grid.Row>

              <Divider />

              <Grid.Row>
                <Grid.Column>
                  <Header as="h4">Actual Start Time</Header>
                  {booking.actualStartTime == null
                    ? "N/A"
                    : new Date(booking.actualStartTime).toLocaleString(
                        "en-AU",
                        options
                      )}
                </Grid.Column>

                <Grid.Column>
                  <Header as="h4">Actual End Time</Header>
                  {booking.actualEndTime == null
                    ? "N/A"
                    : new Date(booking.actualEndTime).toLocaleString(
                        "en-AU",
                        options
                      )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>

      <Divider section />

      {!isCommunityManager && <BookingOrders booking={booking} />}
    </>
  );
};

export default Booking;
