import {
  Table,
  Label,
  Popup,
  Button,
  Icon,
  Grid,
  LabelGroup,
} from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../api";
import { useEffect, useState } from "react";
import { useUserAccess } from "../components/UserAccessManager";
import IssuesModal from "./modals/IssuesModal";

const UserDetails = ({ user }) => {
  const [issues, setIssues] = useState([]);
  const [render, setRender] = useState(Math.random());
  const [isAdminIssueModalOpen, setIsAdminIssueModalOpen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();
  const { isCommunityManager } = useUserAccess();

  const fetchData = async () => {
    if (isCommunityManager) return;

    const accessToken = await getAccessTokenSilently();
    const fetchedIssues = await api.getUserAdminIssues(user.id, accessToken);
    setIssues(fetchedIssues);
  };

  useEffect(() => {
    fetchData();
  }, [render]);

  const handleOpenIssueModal = () => {
    setIsAdminIssueModalOpen(true);
  };

  const handleCloseIssueModal = () => {
    setIsAdminIssueModalOpen(false);
  };

  const isUserSuspended = () => {
    return issues.some((issue) => issue.status === "suspension");
  };

  const getAccountType = (authId) => {
    let accountType = authId.split("|")[0];
    if (accountType === "auth0") {
      return "Username Password";
    } else if (accountType === "google-oauth2") {
      return "Google";
    } else if (accountType === "apple") {
      return "Apple";
    } else return "Click for full Auth0 Id";
  };

  return (
    <>
      <Table color="blue">
        <Table.Body>
          <Table.Row>
            <Table.Cell>First Name</Table.Cell>

            <Table.Cell>
              {isUserSuspended() && (
                <Popup
                  on="hover"
                  content="This user is suspended or banned."
                  trigger={
                    <Icon
                      color="red"
                      name="exclamation circle"
                      size="large"
                      floated="right"
                    />
                  }
                />
              )}
              {user.firstName}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Last Name</Table.Cell>
            <Table.Cell>{user.lastName}</Table.Cell>
          </Table.Row>
          {!isCommunityManager && (
            <>
              <Table.Row>
                <Table.Cell>Phone Number</Table.Cell>
                <Table.Cell>{user.phoneNumber}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Email</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
              </Table.Row>
              {typeof user.authId !== "undefined" && (
                <Table.Row>
                  <Table.Cell>Auth0</Table.Cell>
                  <Popup
                    on="click"
                    pinned
                    position="bottom left"
                    content={user.authId}
                    trigger={
                      <Table.Cell>{getAccountType(user.authId)} </Table.Cell>
                    }
                  />
                </Table.Row>
              )}
              <Table.Row>
                <Table.Cell>Credit Balance</Table.Cell>
                <Table.Cell>${user.creditBalance}</Table.Cell>
              </Table.Row>
            </>
          )}
          <Table.Row>
            <Table.Cell>Services</Table.Cell>
            <Table.Cell>
              {user.requestedServicesList.map((service) => (
                <Label size="medium" key={service}>
                  {service}
                </Label>
              ))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {!isCommunityManager && (
        <Grid textAlign="center">
          <Grid.Row>
            <Button
              color="blue"
              compact
              size="small"
              floated="right"
              onClick={() => handleOpenIssueModal()}
            >
              <Icon name="warning sign" />
              User notes &nbsp;
              <Label horizontal size="tiny" circular content={issues.length} />
            </Button>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <LabelGroup size="small">
                <Label
                  icon="mail"
                  content="Email"
                  href={`https://secure.helpscout.net/mailbox/2ca1deda3521ef0a/new-ticket/?name=${user.firstName}+${user.lastName}&email=${user.email}&subject=International+licence+verification`}
                  target="_blank"
                />
                <Label
                  icon="mail"
                  content="More info required"
                  href={`https://secure.helpscout.net/mailbox/2ca1deda3521ef0a/new-ticket/?name=${user.firstName}+${user.lastName}&email=${user.email}&subject=More+information+required`}
                  target="_blank"
                />
                <Label
                  icon="mail"
                  content="International licence"
                  href={`https://secure.helpscout.net/mailbox/2ca1deda3521ef0a/new-ticket/?name=${user.firstName}+${user.lastName}&email=${user.email}&subject=International+licence+verification`}
                  target="_blank"
                />
                <Label
                  icon="search"
                  content="Search email"
                  href={`https://secure.helpscout.net/search/?query=${user.email}`}
                  target="_blank"
                />
              </LabelGroup>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      <IssuesModal
        isOpen={isAdminIssueModalOpen}
        onClose={handleCloseIssueModal}
        userId={user.id}
        issues={issues}
        onSave={() => fetchData()}
      />
    </>
  );
};

export default UserDetails;
