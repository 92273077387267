import { Table, Button, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";

const VehicleDetails = ({ vehicle, site, compact = false }) => {
  const navigate = useNavigate();

  const handleGetVehicleBookingsClick = (site) => {
    navigate(`/sites/${site.id}/vehiclebookings?name=${site.name}`);
  };

  return (
    <>
      <Table color="blue">
        <Table.Body>
          <Table.Row>
            <Table.Cell>Model</Table.Cell>
            <Table.Cell>
              {vehicle.make} - {vehicle.model}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Registration Plate</Table.Cell>
            <Table.Cell>{vehicle.registrationPlate}</Table.Cell>
          </Table.Row>
          {!compact && (
            <Table.Row>
              <Table.Cell>Etag Number</Table.Cell>
              <Table.Cell>{vehicle.etagNumber}</Table.Cell>
            </Table.Row>
          )}
          <Table.Row>
            <Table.Cell>Hourly Rate</Table.Cell>
            <Table.Cell>{vehicle.hireRatePerHour}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Daily Cap</Table.Cell>
            <Table.Cell>{vehicle.dailyCap}</Table.Cell>
          </Table.Row>
          {!compact && (
            <Table.Row>
              <Table.Cell>Currently Assigned To</Table.Cell>
              <Table.Cell>
                <Popup
                  mouseEnterDelay={500}
                  content="Go to site bookings"
                  trigger={
                    <Button
                      basic
                      compact
                      icon="external alternate"
                      labelPosition="right"
                      content={site.displayName}
                      onClick={() => handleGetVehicleBookingsClick(site)}
                    />
                  }
                />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default VehicleDetails;
