import React, { createContext, useCallback, useContext, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../api";
import { useUserAccess } from "../UserAccessManager";
import { GlobalChatWindow } from "./GlobalChatWindow";
import { OverdueBooking, LateReturn, DelayedBooking } from "./MessageTemplates";

export const ChatContext = createContext({
  chatWindowState: {
    open: false,
    selectedUser: null,
    to: "",
    message: "",
    status: null,
  },
  messages: { state: "success", value: [] },
  setChatWindowState: () => {},
  setMessages: () => {},
});

/**
 * Displays a chat window on top of its children.\
 * Note: useChatActions should be used in the children instead of ChatContext.
 */
export const ChatWindowManager = ({ children }) => {
  const { isCommunityManager } = useUserAccess();

  const [chatWindowsState, setChatWindowState] = useState({
    open: false,
    selectedUser: null,
    to: "",
    message: "",
    status: null,
  });
  const [messages, setMessages] = useState({ state: "success", value: [] });

  return (
    <ChatContext.Provider
      value={{
        chatWindowState: chatWindowsState,
        messages: messages,
        setChatWindowState,
        setMessages: setMessages,
      }}
    >
      {children}
      {!isCommunityManager && <GlobalChatWindow />}
    </ChatContext.Provider>
  );
};

/**
 * Returns the chat window context for use in the chat window.
 */
export function useGlobalChatWindowManager() {
  const { getAccessTokenSilently } = useAuth0();
  const { chatWindowState, messages, setChatWindowState, setMessages } =
    useContext(ChatContext);

  /**
   * Fetches messages if a user is selected, and updates the status.
   */
  const fetchMessages = useCallback(
    async (userId) => {
      const accessToken = await getAccessTokenSilently();

      if (userId) {
        setMessages((prev) => ({ state: "loading", ...prev }));

        const fetchedMessages = await api.getMessagesForConversation(
          userId,
          accessToken
        );

        setMessages({ state: "success", value: fetchedMessages });
      }
    },
    [getAccessTokenSilently, setMessages]
  );

  /**
   * Sets the selected user and the phone number input.
   */
  const selectUser = useCallback(
    (user) => {
      setChatWindowState((prev) => ({
        ...prev,
        selectedUser: user,
        to: user.phoneNumber,
      }));
      fetchMessages(user.id);
    },
    [fetchMessages, setChatWindowState]
  );

  /**
   * Sets phone number input value.\
   * Clears the input if the phone number is not provided.
   */
  const handlePhoneNumberChange = useCallback(
    (to) => {
      setChatWindowState((prev) => ({ ...prev, to: to ?? "" }));
    },
    [setChatWindowState]
  );

  /**
   * Updates the message input state and resets the message status.
   */
  const handleMessageChange = useCallback(
    (newMessage) => {
      setChatWindowState((prev) => ({
        ...prev,
        status: null,
        message: newMessage,
      }));
    },
    [setChatWindowState]
  );

  /**
   * Changes the status of the send attempt.\
   * Removes the sent message from the input if the send attempt was successful.
   */
  const updateMessageStatus = useCallback(
    (newStatus) => {
      setChatWindowState((prev) => ({
        ...prev,
        status: newStatus,
        message: newStatus === "success" ? "" : prev.message,
      }));
    },
    [setChatWindowState]
  );

  /**
   * Attempts to send an SMS message and updates the status.
   */
  const handleSendSMS = useCallback(
    async (e) => {
      e.preventDefault();
      const data = new FormData(e.target);
      const accessToken = await getAccessTokenSilently();

      updateMessageStatus("sending");

      if (chatWindowState.selectedUser) {
        const requestObject = {
          userId: chatWindowState.selectedUser.id,
          to: addCodesToNumber(data.get("to")),
          body: data.get("message"),
        };

        try {
          await api.sendSms(accessToken, requestObject);

          fetchMessages(chatWindowState.selectedUser.id);
          updateMessageStatus("success");
        } catch (e) {
          console.log(e);
          updateMessageStatus("failed");
        }
      } else {
        updateMessageStatus("failed");
      }
    },
    [
      chatWindowState.selectedUser,
      fetchMessages,
      getAccessTokenSilently,
      updateMessageStatus,
    ]
  );

  return {
    chatWindowState,
    messages,
    fetchMessages,
    handlePhoneNumberChange,
    handleMessageChange,
    handleSendSMS,
    selectUser,
  };
}

/**
 * Returns the chat window context for use in any file.
 */
export function useChatActions() {
  const { setChatWindowState } = useContext(ChatContext);
  const { selectUser } = useGlobalChatWindowManager();

  /**
   * If user is provided, selects that user\
   * and opens the chat window for buttons outside of the chat window.\
   * Otherwise toggles chat window open or closed.
   */
  const toggleOpenChatWindow = useCallback(
    (user) => {
      if (user) {
        selectUser(user);
        setChatWindowState((prev) => ({ ...prev, open: true }));
      } else {
        setChatWindowState((prev) => ({ ...prev, open: !prev.open }));
      }
    },
    [selectUser, setChatWindowState]
  );

  /**
   *
   */
  const insertTemplate = useCallback(
    (templateName, booking) => {
      let template = "";

      const dateTimeFormat = new Intl.DateTimeFormat("en-AU", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });

      switch (templateName) {
        case "Overdue booking":
          template = OverdueBooking(
            booking.user.firstName,
            booking.vehicle.registrationPlate
          );
          break;
        case "Late return":
          template = LateReturn(
            booking.user.firstName,
            booking.vehicle.registrationPlate,
            dateTimeFormat.format(new Date(booking.endTime))
          );
          break;
        case "Delayed booking":
          template = DelayedBooking(
            booking.user.firstName,
            booking.vehicle.registrationPlate,
            dateTimeFormat.format(new Date(booking.startTime))
          );
          break;
        default:
          break;
      }

      setChatWindowState((prev) => ({ ...prev, message: template }));
      toggleOpenChatWindow(booking.user);
    },
    [setChatWindowState, toggleOpenChatWindow]
  );

  return { toggleOpenChatWindow, insertTemplate };
}

/**
 * Removes spaces and replaces the initial 0 with +614 if necessary.
 * @param {string} phoneNumber
 * @returns {string} A phone number in the +614######## format.
 */
function addCodesToNumber(phoneNumber) {
  const number = phoneNumber.replaceAll(" ", "");

  if (number.length === 10) {
    return "+61" + number.slice(1);
  }

  return phoneNumber;
}
