import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Comment,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Label,
  Message,
  Modal,
  Popup,
  Segment,
  Table,
  TextArea,
} from "semantic-ui-react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../../api";
import { Loader } from "../Loader";
import { isLoading } from "../../utils/isLoading";
import { TextInput } from "../TextInput";
import { getPaginatedItems, Pagination } from "../Pagination";
import { useUserAccess } from "../UserAccessManager";
import {
  useChatActions,
  useGlobalChatWindowManager,
} from "./ChatWindowManager";

/**
 * Displays a button that opens a chat window for sending SMS messages.
 */
export const GlobalChatWindow = () => {
  const {
    chatWindowState,
    messages,
    fetchMessages,
    handlePhoneNumberChange,
    handleSendSMS,
    handleMessageChange,
  } = useGlobalChatWindowManager();
  const { isCommunityManager } = useUserAccess();
  const { toggleOpenChatWindow } = useChatActions();

  return (
    <Segment
      basic
      style={{
        position: "fixed",
        bottom: "40px",
        right: "40px",
      }}
    >
      {chatWindowState.open && (
        <Card raised fluid style={{ width: "320px" }}>
          <Card.Content>
            <Form onSubmit={handleSendSMS}>
              <Form.Field>
                <User chatWindowState={chatWindowState} />
              </Form.Field>

              <Form.Field>
                <PhoneNumberInput
                  chatWindowState={chatWindowState}
                  handlePhoneNumberChange={handlePhoneNumberChange}
                />
              </Form.Field>

              <MessageArea
                chatWindowState={chatWindowState}
                messages={messages}
              />

              <Form.Field
                error={chatWindowState.status === "failed"}
                disabled={chatWindowState.status === "sending"}
              >
                <TextArea
                  name="message"
                  placeholder="Aa"
                  required
                  minLength={1}
                  value={chatWindowState.message}
                  onChange={(_, { value }) => handleMessageChange(value)}
                  style={{ resize: "none" }}
                />
              </Form.Field>

              <Form.Field>
                <MessageButtons
                  chatWindowState={chatWindowState}
                  messages={messages}
                  fetchMessages={fetchMessages}
                />
              </Form.Field>
            </Form>
          </Card.Content>
        </Card>
      )}

      <FloatingActionButton
        chatWindowState={chatWindowState}
        isCommunityManager={isCommunityManager}
        toggleOpenChatWindow={toggleOpenChatWindow}
      />
    </Segment>
  );
};

/**
 * Displays button for selecting a user, and the user's name and email address.
 * @param {{
 *   message: {
 *     id: string
 *     body: string
 *     dateCreated: string
 *     status: string
 *     from: string
 *     to: string
 *   }
 * }} props
 */
const User = ({ chatWindowState }) => (
  <Grid columns={2}>
    <Grid.Column style={{ width: "auto", paddingRight: "0.5rem" }}>
      <UserSelector />
      {!chatWindowState.selectedUser && (
        <Label pointing="left">Select a user</Label>
      )}
    </Grid.Column>

    <Grid.Column
      style={{
        display: "flex",
        alignItems: "center",
        width: "auto",
        paddingLeft: "0.5rem",
      }}
    >
      {chatWindowState.selectedUser && (
        <Header size="small">
          {`${chatWindowState.selectedUser.firstName} ${chatWindowState.selectedUser.lastName}`}
          <Header.Subheader>
            {chatWindowState.selectedUser.email}
          </Header.Subheader>
        </Header>
      )}
    </Grid.Column>
  </Grid>
);

/**
 * Displays a clearable phone number input.
 * @param {{
 *   chatWindowState: {
 *     open: boolean,
 *     to: string,
 *     message: string,
 *     status: string?
 *   }
 *   handlePhoneNumberChange: () => {}
 * }} props
 */
const PhoneNumberInput = ({ chatWindowState, handlePhoneNumberChange }) => (
  <Input
    type="tel"
    name="to"
    placeholder="+614########"
    value={chatWindowState.to}
    onChange={(_, data) => handlePhoneNumberChange(data.value)}
    iconPosition="left"
    action
  >
    <Icon name="phone" />
    <input
      minLength={10}
      maxLength={12}
      // Accepts '+614 ### ### ###' or '04 #### ####'
      pattern="(\+614\d{2}(\d{3}){2})|(04(\d{4}){2})"
      required
    />
    <Button basic icon="close" onClick={() => handlePhoneNumberChange()} />
  </Input>
);

/**
 * Displays a list of messages and/or an error message.
 * @param {{
 *   chatWindowState: {
 *     open: boolean,
 *     to: string,
 *     message: string,
 *     status: string?
 *   }
 *   messages: {
 *     state: string,
 *     value: [{
 *       id: string
 *       body: string
 *       dateCreated: string
 *       status: string
 *       from: string
 *       to: string
 *     }]}
 * }} props
 */
const MessageArea = ({ chatWindowState, messages }) => {
  const messageAreaEndRef = useRef(null);

  // Scrolls to the latest message when messages are fetched.
  useEffect(() => {
    if (messageAreaEndRef.current !== null) {
      const messageArea = messageAreaEndRef.current.parentElement;
      messageArea.scrollTo(0, messageArea.scrollHeight);
    }
  }, [messages]);

  return (
    <Segment
      placeholder
      basic
      loading={isLoading(messages)}
      style={{
        padding: 0,
        justifyContent: "flex-end",
        maxHeight: "250px",
      }}
    >
      <Comment.Group style={{ margin: 0, overflow: "auto" }}>
        {messages.value.map((message) => (
          <SMSMessage key={message.id} message={message} />
        ))}

        {chatWindowState.status === "failed" && (
          <Message icon negative>
            <Icon name="times circle" />

            <Message.Content>
              <Message.Header>Error</Message.Header>
              <p>Failed to send message</p>
            </Message.Content>
          </Message>
        )}

        <div ref={messageAreaEndRef} />
      </Comment.Group>
    </Segment>
  );
};

/**
 * Displays a message with the sender, date sent, and the message body
 * @param {{
 *   message: {
 *     id: string
 *     body: string
 *     dateCreated: string
 *     status: string
 *     from: string
 *     to: string
 *   }
 * }} props
 */
const SMSMessage = ({ message }) => {
  const localisedDate = new Intl.DateTimeFormat("en-AU", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(message.dateCreated));

  return (
    <Comment>
      <Comment.Content>
        <Comment.Author>
          {message.from}
          <Comment.Metadata>{localisedDate}</Comment.Metadata>
        </Comment.Author>

        <Comment.Text>{message.body}</Comment.Text>
      </Comment.Content>
    </Comment>
  );
};

/**
 * Displays a button that fetches messages and a button that sends a message.
 * @param {{
 *   chatWindowState: {
 *     open: boolean,
 *     to: string,
 *     message: string,
 *     status: string?
 *   }
 *   messages: {state: string, Array}
 *   fetchMessages: () => {}
 * }} props
 */
const MessageButtons = ({ chatWindowState, messages, fetchMessages }) => (
  <>
    <Popup
      trigger={
        <Button
          basic
          icon="redo"
          floated="left"
          onClick={() => fetchMessages(chatWindowState.selectedUser.id)}
          loading={isLoading(messages)}
          disabled={isLoading(messages) || !chatWindowState.selectedUser}
          type="reset"
        />
      }
      content="Fetch new messages"
      position="top center"
      mouseEnterDelay={800}
    />

    <Button
      type="submit"
      loading={chatWindowState.status === "sending"}
      color="blue"
      floated="right"
      style={{ margin: 0 }}
      disabled={
        chatWindowState.status === "sending" ||
        !chatWindowState.selectedUser ||
        !chatWindowState.to ||
        !chatWindowState.message
      }
    >
      <Icon name="send" />
      Send
    </Button>
  </>
);

/**
 * Displays a modal that contains a paginated and searchable list of users.\
 * When a user is selected, their name is displayed alongside their number in the recipient input field.
 */
const UserSelector = () => {
  const { selectUser } = useGlobalChatWindowManager();
  const { getAccessTokenSilently } = useAuth0();

  const [open, setOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [users, setUsers] = useState({ state: "loading", value: [] });
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filters, setFilters] = useState({
    searchValue: "",
  });

  /**
   * Returns an array of users that meet the filter conditions.\
   * Each user is added to the array only if every filter is true.
   */
  const applyFilters = useCallback((users, filters) => {
    return users.value.filter((user) => {
      // Search in name, email, or phone number
      const searchMatch =
        filters.searchValue === "" ||
        (user.firstName + " " + user.lastName)
          .toLowerCase()
          .includes(filters.searchValue.toLowerCase()) ||
        user.email.startsWith(filters.searchValue.toLowerCase()) ||
        user.phoneNumber.includes(filters.searchValue.toLowerCase());

      return searchMatch;
    });
  }, []);

  const updateFilters = (updatedFilter) => {
    setFilters((prev) => ({ ...prev, ...updatedFilter }));
  };

  /**
   * Filters the users and resets the pagination.
   */
  const filterUsers = useCallback(
    (users, filters) => {
      const filteredUserResults = applyFilters(users, filters);
      setFilteredUsers(filteredUserResults);
      setActivePage(1);
    },
    [applyFilters]
  );

  /**
   * Closes the modal and sets the selected user.
   */
  const handleClose = useCallback(
    (user) => {
      setOpen(false);
      selectUser(user);
    },
    [selectUser]
  );

  /**
   * Opens the modal and fetches the users.
   */
  const handleOpen = useCallback(async () => {
    setOpen(true);

    const accessToken = await getAccessTokenSilently();
    const fetchedUsers = await api.getUsers(accessToken);

    setUsers({ state: "success", value: fetchedUsers });
  }, [getAccessTokenSilently]);

  /**
   * Updates the filters when the filters change.
   */
  useEffect(() => {
    filterUsers(users, filters);
  }, [filterUsers, filters, users]);

  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={(e) => handleOpen(e)}
      trigger={<Button basic icon="user" style={{ margin: 0 }} />}
    >
      <Modal.Header>Select a User to Message</Modal.Header>
      <Modal.Content>
        <Loader isLoading={isLoading(users)}>
          <Grid stackable>
            <Grid.Column width={4}>
              <TextInput
                placeholder="Name, email or phone"
                onSubmit={(searchValue) => updateFilters({ searchValue })}
              />
            </Grid.Column>
          </Grid>

          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email Address</Table.HeaderCell>
                <Table.HeaderCell>Phone Number</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {getPaginatedItems(filteredUsers, activePage).map((user) => (
                <Table.Row
                  key={user.id}
                  onClick={() => handleClose(user)}
                  style={{ cursor: "pointer" }}
                >
                  <Table.Cell>{`${user.firstName} ${user.lastName}`}</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>{user.phoneNumber}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>

          <Grid stackable>
            <Grid.Column width={4}>
              <Pagination
                activePage={activePage}
                onPageChange={(page) => setActivePage(page)}
                itemQuantity={filteredUsers.length}
              />
            </Grid.Column>
          </Grid>
        </Loader>
      </Modal.Content>
    </Modal>
  );
};

/**
 * Displays a button on top of the page in the corner of the screen.\
 * When clicked, it toggles the chat window open or closed.
 * @param {{
 *   chatWindowState: {
 *     open: boolean,
 *     to: string,
 *     message: string,
 *     status: string?
 *   }
 *   isCommunityManager: boolean
 *   toggleOpenChatWindow: () => {}
 * }} props
 */
const FloatingActionButton = ({
  chatWindowState,
  isCommunityManager,
  toggleOpenChatWindow,
}) => (
  <Popup
    trigger={
      <Button
        color="teal"
        circular
        icon={chatWindowState.open ? "times" : "conversation"}
        onClick={() => toggleOpenChatWindow()}
        floated="right"
        size="huge"
        disabled={isCommunityManager}
        style={{
          boxShadow:
            "0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34, 36, 38, .12), 0 2px 10px 0 rgba(34, 36, 38, .15)",
        }}
      />
    }
    content={chatWindowState.open ? "Close chat window" : "Open chat window"}
    position="left center"
    mouseEnterDelay={800}
  />
);
